.footer-container {
	width: auto;
	padding: 3rem 0rem;
	display: flex;
	flex-direction: column;
	.footer-inner-container {
		width: 100%;
		display: flex;
		flex-direction: column;
	}
	.logo {
		margin: 2rem auto 0rem auto;
		width: 5rem;
	}
	.nav-item-container {
		width: 25%;
		display: flex;
		padding: 0.5rem;
		margin-top: 1rem;
		text-decoration: none;
		color: white;
	}
	@media screen and (max-width: 600px) {
		.nav-item-container {
			width: 50%;
		}
	}
	.nav-item-container:hover {
		color: white;
		cursor: pointer;
	}
	.nav-item {
		width: 100%;
	}
	.social-icon {
		color: white;
		margin: 0rem 1rem;
		width: 24px;
		height: 24px;
	}

	.singularity-credit {
		a {
			display: flex;
		}
		img {
			width: auto;
			height: 10rem;
			margin: 0 auto;
		}
	}


}
